<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="filters">
        <formulate-input v-model="search" placeholder="Search" type="text" @input="searchDealers"></formulate-input>
      </div>
      <div class="buttons">
        <formulate-input v-model="withoutUsers" :label="$t('Dealers zonder gebruiker')" type="checkbox" @change="fetchDealers(0)"></formulate-input>
        <button class="btn btn-download" @click="exportVacations">Exporteer vakantieplanning</button>
        <button class="btn btn-download" @click="exportOverview">Exporteer overzicht</button>
        <router-link v-if="checkPermissions(['dealer.create'])" :to="{name: 'admin.dealer.add'}" class="btn btn-add">
          <span>{{ $t('Dealer toevoegen') }}</span>
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="dealers">
              <thead>
              <tr>
                <th :class="(sortBy === 'dealer.id' ? 'active ' + sortDir : '')" class="id" @click="sortCol('dealer.id')">
                  <div>{{ $t('ID') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.name' ? 'active ' + sortDir : '')" @click="sortCol('dealer.name')">
                  <div>{{ $t('Naam') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.email' ? 'active ' + sortDir : '')" @click="sortCol('dealer.email')">
                  <div>{{ $t('E-mail') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.client_number' ? 'active ' + sortDir : '')" @click="sortCol('dealer.client_number')">
                  <div>{{ $t('Klantnummer') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.active' ? 'active ' + sortDir : '')" @click="sortCol('dealer.active')">
                  <div>{{ $t('Actief') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.updated' ? 'active ' + sortDir : '')" @click="sortCol('dealer.updated')">
                  <div>{{ $t('Gewijzigd') }}</div>
                </th>
                <th :class="(sortBy === 'dealer.created' ? 'active ' + sortDir : '')" @click="sortCol('dealer.created')">
                  <div>{{ $t('Aangemaakt') }}</div>
                </th>
              </tr>
              </thead>

              <tbody v-if="dealers.length > 0">
              <tr v-for="dealer in dealers" :key="dealer.id" @click="$router.push({name: 'admin.dealer', params: {id: dealer.id}})" @contextmenu.prevent.stop="dealerRightClick($event, dealer)">
                <td class="id">{{ dealer.id }}</td>
                <td>{{ dealer.name }}</td>
                <td>{{ dealer.email }}</td>
                <td>{{ dealer.client_number }}</td>
                <td><img v-if="dealer.active" alt="check" src="@/assets/img/check.svg" width="15">&nbsp;</td>
                <td>{{ dealer.updated ? dealer.updated.toFormat('dd/LL/yyyy HH:mm') : '' }}</td>
                <td>{{ dealer.created.toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ $t(`${pageTotal.min} - ${pageTotal.max} van ${total} dealers`) }}
            </div>

            <div class="buttons">
              <button :disabled="page === 0" class="btn-inverse" @click="prevPage()">Prev</button>
              <button :disabled="dealers.length !== perPage" class="btn-inverse" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu ref="vueSimpleContextMenu" :options="contextMenuOptions" element-id="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>

    <div ref="deactivateMessage" style="display: none;">
      <div>
        <p>Ben je zeker dat je deze dealer en de gekoppelde gebruikers die aan geen andere dealer(s) gekoppeld zijn wenst te deactiveren?</p>
        <br/>
        <strong v-if="singleUsers.length > 0">Gekoppelde gebruikers:</strong>
        <ul v-if="singleUsers.length > 0" class="single-users-list">
          <li v-for="user in singleUsers" :key="user.id">
            <span v-if="user.first_name && user.last_name">{{ user.first_name }} {{ user.last_name }} - </span>{{ user.email }}
          </li>
        </ul>
      </div>
    </div>

    <div ref="activateMessage" style="display: none;">
      <div>
        <p>Ben je zeker dat je deze dealer terug wenst te activeren?</p>
        <br/>
        <p>
          <strong>Note:</strong> gedeactiveerde gebruikers die in het verleden aan deze dealer waren gekoppeld, worden niet automatisch geactiveerd.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import Swal from 'sweetalert2'

export default {
  components: { PageTitle },
  data: function () {
    return {
      withoutUsers: false,
      singleUsers: [],
      search: '',
      defaultContextMenuOptions: [
        { id: 'activateDealer', name: 'Dealer activeren' },
        { id: 'deactivateDealer', name: 'Dealer deactiveren' }
      ],
      contextMenuOptions: []
    }
  },
  async mounted () {
    await this.fetchDealers(this.page)
  },
  methods: {
    async exportOverview () {
      await ApiService.exportAllDealers().then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'dealers.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    async exportVacations () {
      await ApiService.exportDealerVacations().then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'vacations.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    async sortCol (sortBy) {
      let sortDir = 'asc'

      if (sortBy === this.sortBy) {
        sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      }

      await this.$store.dispatch('DealersModule/fetchDealers', {
        sortBy: sortBy,
        sortDir: sortDir,
        search: this.search
      })
    },
    async searchDealers () {
      await this.fetchDealers(0)
    },
    dealerRightClick: async function (event, dealer) {
      if (dealer.active) {
        this.contextMenuOptions = [{ id: 'deactivateDealer', name: 'Dealer deactiveren' }]
      } else {
        this.contextMenuOptions = [{ id: 'activateDealer', name: 'Dealer activeren' }]
      }

      await ApiService.fetchDealerSingleUsers(dealer.id).then(res => {
        if (res.status === 200) {
          this.singleUsers = res.data
        }
      })

      if (this.checkPermissions(['dealer.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, dealer)
      }
    },
    contextOptionClicked: async function (event) {
      if (event.option.id === 'deactivateDealer') {
        await Swal.fire({
          title: event.option.name,
          html: this.$refs.deactivateMessage.innerHTML,
          showCancelButton: true,
          cancelButtonText: 'Nee',
          showConfirmButton: true,
          confirmButtonText: 'Ja'
        }).then(async res => {
          if (res.isConfirmed) {
            await this.deleteDealer(event.item.id)
          }
        })
      } else {
        await Swal.fire({
          title: event.option.name,
          html: this.$refs.activateMessage.innerHTML,
          showCancelButton: true,
          cancelButtonText: 'Nee',
          showConfirmButton: true,
          confirmButtonText: 'Ja'
        }).then(async res => {
          if (res.isConfirmed) {
            event.item.active = true
            event.item.users = event.item.users.map(user => user.id)
            await ApiService.saveDealer(event.item).then(async res => {
              if (res.status === 200) {
                await this.$store.dispatch('DealersModule/fetchDealers')
                notification('Dealer succesvol geactiveerd!')
              }
            })
          }
        })
      }
    },
    deleteDealer: async function (dealerId) {
      ApiService.deleteDealer(dealerId).then(async res => {
        if (res.status === 200) {
          await this.$store.dispatch('DealersModule/fetchDealers')
          notification('Dealer succesvol gedeactiveerd!')
        }
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        // await this.$store.dispatch('DealersModule/fetchDealers', { page: this.page - 1, perPage: this.perPage })
        await this.fetchDealers(this.page - 1)
      }
    },
    nextPage: async function () {
      if (this.dealers.length === this.perPage) {
        // await this.$store.dispatch('DealersModule/fetchDealers', { page: this.page + 1, perPage: this.perPage })
        await this.fetchDealers(this.page + 1)
      }
    },
    async fetchDealers (page) {
      await this.$store.dispatch('DealersModule/fetchDealers', {
        page: page,
        perPage: this.perPage,
        search: this.search,
        withoutUsers: this.withoutUsers
      })
    }
  },
  computed: {
    total: function () {
      return this.$store.state.DealersModule.total
    },
    page: function () {
      return this.$store.state.DealersModule.page
    },
    perPage: function () {
      return this.$store.state.DealersModule.perPage
    },
    sortBy: function () {
      return this.$store.state.DealersModule.sortBy
    },
    sortDir: function () {
      return this.$store.state.DealersModule.sortDir
    },
    dealers: function () {
      const dealers = []
      const rawDealers = this.$store.getters['DealersModule/getDealers']

      if (rawDealers) {
        rawDealers.forEach((dealerRow, i) => {
          const dealer = {}

          for (const prop in dealerRow) {
            if ((prop === 'created' || prop === 'updated') && dealerRow[prop] != null) {
              dealer[prop] = DateTime.fromISO(dealerRow[prop])
            } else {
              dealer[prop] = dealerRow[prop]
            }
          }

          dealers.push(dealer)
        })
      }

      return dealers
    },
    pageTotal: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
